<template>
  <div class="card">
    <div class="card-header">
      <button
        class="btn btn-sm btn-outlined"
        v-show="!address.primary"
        @click="locationClicked(address.id)"
      >
        {{ $t("global.make_primary") }}
      </button>
      <button class="btn btn-sm btn-primary frozen" v-show="address.primary">
        {{ $t("global.primary") }}
      </button>
    </div>

    <div class="card-body">
      <p class="text-uppercase color-secondary mb-0">
        {{ $t("addresses.title_singular") }}
      </p>
      <p>{{ address.country.name }}, {{ address.city }}</p>

      <p class="text-uppercase color-secondary mb-0 mt-2">
        {{ $t("addresses.fields.description") }}
      </p>
      <p class="dotdotdot font-size-default lines-2 expanded">
        {{ address.address_description }}
      </p>
    </div>

    <div class="card-footer row g-0">
      <router-link
        class="btn col font-size-default"
        :to="{ name: 'AddressesEdit', params: { id: address.id } }"
      >
        <i class="far fa-edit me-1"></i>
        {{ $t("global.edit") }}
      </router-link>

      <div class="col" v-if="!address.primary">
        <confirm-modal
          modalDialogClasses="modal-notice"
          v-bind:title="$t('global.notice')"
          v-bind:body="$t('global.confirm_delete')"
          confirmBtnClass="btn-danger"
          declinedBtnClass="btn-default"
          @confirmed="deleteAddressEvent(address.id)"
        >
          <button class="btn w-100 font-size-default">
            <i class="far fa-trash-alt me-1"></i> {{ $t("global.delete") }}
          </button>
        </confirm-modal>
      </div>

      <div class="col" v-if="address.primary">
        <button class="btn w-100 font-size-default disabled">
          <i class="far fa-trash-alt me-1"></i> {{ $t("global.delete") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ConfirmModal from "@/components/ConfirmModal.vue";
export default {
  name: "AddressCard",
  components: { ConfirmModal },
  props: ["address"],
  methods: {
    ...mapActions(["setPrimaryAddress", "deleteAddress"]),
    locationClicked(locationId) {
      this.setPrimaryAddress(locationId);
    },
    deleteAddressEvent(address_id) {
      this.deleteAddress(address_id);
    },
  },
  computed: {
    ...mapGetters(["primaryAddress"]),
  },
};
</script>

<style lang='scss' scoped>
.card {
  margin-bottom: map-get($spacings, "default");
  .card-header {
    text-align: right;
    padding: map-get($spacings, "small") map-get($spacings, "medium");
    background-color: inherit;
    border-bottom: 0;
  }

  .card-body {
    padding: map-get($spacings, "medium");
  }

  .card-footer {
    padding: 0;

    .btn {
      position: relative;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 1px;
        height: 80%;
        background-color: map-get($colors, "gray");
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}
</style>
