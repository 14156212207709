<template>
  <span>
    <span data-bs-toggle="modal" v-bind:data-bs-target="'#' + random_id">
      <slot></slot>
    </span>
    <div
      v-bind:id="random_id"
      class="modal fade"
      v-bind:class="modalClasses"
      tabindex="-1"
    >
      <div class="Aligner h-100" :class="modalWrapper">
        <div
          class="modal-dialog modal-dialog-centered"
          v-bind:class="modalDialogClasses"
        >
          <div class="modal-content">
            <div class="modal-header">
              <div>
                <slot name="header">
                  <h5 class="modal-title font-weight-bold" v-if="title">
                    {{ title }}
                  </h5>
                </slot>
              </div>
              <i
                v-if="closeButton == true"
                class="fas fa-times-circle close"
                v-bind:aria-label="$t('global.cancel')"
                data-bs-dismiss="modal"
              ></i>
            </div>
            <div class="modal-body">
              <slot name="body">
                <p class="mb-0" v-if="body" v-html="body"></p>
              </slot>
            </div>
            <div
              v-if="showButtons == true"
              class="modal-footer"
              v-bind:class="footerLayout"
            >
              <button
                type="button"
                v-bind:class="confirmBtnClassComputed"
                v-bind:disabled="confirmBtnDisabled"
                data-bs-dismiss="modal"
                @click="$emit('confirmed', true)"
              >
                {{ confirmBtn ? confirmBtn : $t("global.confirm") }}
              </button>
              <div
                class="clear"
                v-if="footerLayout == 'centered-vertically'"
              ></div>
              <button
                v-if="closeButton == true"
                type="button"
                v-bind:class="declinedBtnClassComputed"
                v-bind:disabled="cancelBtnDisabled"
                data-bs-dismiss="modal"
                @click="$emit('declined', true)"
              >
                {{ cancelBtn ? cancelBtn : $t("global.cancel") }}
              </button>
              <div v-else class="checkbox-container pb-3 d-flex Aligner w-100">
                <input
                  :id="check_box_random_id"
                  ref="checkBoxValue"
                  type="checkbox"
                />
                <label :for="check_box_random_id" class="text p-2">
                  {{ checkBoxText }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  name: "ConfirmModal",
  props: {
    modalClasses: String,
    modalDialogClasses: String,
    title: String,
    body: String,
    footerLayout: {
      type: String,
      default: "right",
    },
    cancelBtn: String,
    confirmBtn: String,
    confirmBtnDisabled: {
      type: Boolean,
      default: false,
    },
    cancelBtnDisabled: {
      type: Boolean,
      default: false,
    },
    confirmBtnClass: String,
    declinedBtnClass: String,
    closeButton: {
      type: Boolean,
      default: true,
    },
    checkBoxText: String,
    modalWrapper: {
      type: String,
      default: "modal-dialog",
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      random_id: Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, ""),
      check_box_random_id: Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, ""),
    };
  },
  computed: {
    confirmBtnClassComputed: function () {
      if (this.confirmBtnClass) {
        return "btn " + this.confirmBtnClass;
      } else {
        return "btn btn-primary";
      }
    },
    declinedBtnClassComputed: function () {
      if (this.declinedBtnClass) {
        return "btn " + this.declinedBtnClass;
      } else {
        return "btn btn-light";
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.modal {
  &:hover {
    cursor: default;
  }

  &.clean {
    .modal-header {
      background-color: inherit;
      border: 0;
    }
    .modal-body {
      padding-top: 0;
      padding-bottom: 0;
    }

    .modal-footer {
      border: 0;
    }
  }

  .modal-footer {
    .clear {
      width: 100%;
      float: left;
      margin: 0;
    }
    .checkbox-container {
      .text {
        color: map-get($colors, "secondary");
      }
    }

    &.right {
      justify-content: flex-end;
    }

    &.left {
      justify-content: flex-start;
    }

    &.centered,
    &.centered-horizontally {
      justify-content: center;
    }

    &.centered-vertically {
      width: auto;
      justify-content: center;

      .btn {
        min-width: 300px;
        clear: left;
      }
    }
  }
}
</style>
