<template>
  <div class="container">
    <div class="pt-2 font-size-xl">
      {{ $t("addresses.manage_your_addresses") }}
    </div>

    <div class="row pt-3">
      <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 newAddress">
        <router-link
          class="color-secondary text-decoration-none"
          :to="{ name: 'AddressesCreate' }"
        >
          <div class="card Aligner">
            <img src="@/assets/plus_circled.svg" />
            <div
              class="pt-3 text-uppercase color-secondary text-decoration-none"
            >
              {{ $t("addresses.create") }}
            </div>
          </div>
        </router-link>
      </div>

      <div
        class="col-sm-6 col-md-6 col-lg-4 col-xl-3"
        v-for="address in addresses"
        v-bind:key="address.id"
      >
        <address-card v-bind:address="address" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddressCard from "@/views/Addresses/partials/AddressCard.vue";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";
export default {
  components: { AddressCard },
  name: "Addresses",
  setup() {
    const {t} = useI18n() // use as global scope
    useMeta({
      title: t("addresses.page_title"),
    })
  },
  computed: {
    ...mapGetters(["addresses"]),
  },
};
</script>

<style lang="scss" scoped>
.newAddress {
  margin-bottom: map-get($spacings, "default");
  &:hover {
    cursor: pointer;
  }

  .card {
    border-style: dashed;
    height: 100%;
    min-height: 255px;
    position: relative;
    text-decoration: none;

    img {
      width: 60px;
    }
  }
}
</style>


